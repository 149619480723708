<template>
  <div class="SelectSkin" v-show="isshow">
    <div class="main flex column">
      <div class="title flex allcenter">
        <div class="flex allcenter" style="width: 90%;">登录</div>
        <span class="flex" @click="hidlogin" style="cursor: pointer;">x</span>
      </div>
      <div class="form flex column allcenter">
        <el-form :model="passForm" ref="passForm" :rules="passRules" label-width="50px">
          <el-form-item prop="username" label="账号:">
            <el-input placeholder="请输入账号" v-model="passForm.username" class="searchinput">
            </el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码:">
            <el-input placeholder="请输入密码" v-model="passForm.password" show-password class="searchinput">
            </el-input>
          </el-form-item>
        </el-form>
        <div class="flex paybtn allcenter">
          <!-- <el-button @click="hidlogin" type="cancel" class="flex allcenter diaoff"><span class="qx">取消</span>
          </el-button> -->
          <el-button @click="ykLogin" type="cancel" class="flex allcenter diaoff"><span class="qx">游客登录</span>
          </el-button>
          <el-button type="determine" @click="confirm('passForm')" style="margin-left: 26px"
            class="flex allcenter diaon"><span class="qr">确定</span></el-button>
          <span @click="goZc" class="zc"  ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";
export default {
  data() {
    const validatorUserName = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("账号不能为空"));
      } else {
        callback();
      }
    };
    const validatorPassword = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    return {
      isshow: false,
      passForm: {
        username: "",
        password: "",
      },
      passRules: {
        username: [{ validator: validatorUserName, trigger: "blur" }],
        password: [{ validator: validatorPassword, trigger: "blur" }],
      },
    };
  },
  methods: {
    goZc() {
      window.open("http://www.3000y.com.cn/member.php?mod=register", 'newwindow');
    },
    ykLogin() {
      const userInfo = {
        utoken: 'visitor',
        userLoginDTO: { id: 'xx', username: "游客", headUrl: null }
      }
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      this.$message({
        message: "登录成功",
        type: "success",
        center: true,
      });
      this.$emit('loginOk')
      this.$emit("hidlogin2", false);
      this.passForm.password = "";
      this.passForm.username = "";
      this.$bus.$emit("registered", userInfo);
    },
    hidlogin(e) {
      this.$emit("hidlogin", false);
      this.passForm.password = "";
      this.passForm.username = "";
      this.$refs.passForm.clearValidate();
      this.$bus.$emit('hidDialog', false)
    },

    // 确认
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api
            .login(this.passForm)
            .then((res) => {
              if (!res) {
                this.$message({
                  message: "密码错误，请重新输入",
                  type: "error",
                  center: true,
                });
                this.passForm.password = "";
              } else {
                //  String dz =res.js;
                //  int index =theurl.indexof()

                sessionStorage.setItem("userInfo", JSON.stringify(res));
                this.$message({
                  message: "登录成功",
                  type: "success",
                  center: true,
                });
                this.$emit('loginOk')
                this.$emit("hidlogin2", false);
                this.passForm.password = "";
                this.passForm.username = "";
                this.$bus.$emit("registered", res);
                // let url = res.js.match(/(?<=src=")(.*?)(?=")/)[0];
                let url = res.js.match(new RegExp('(?<=src=")(.*?)(?=")'))[0];
                sessionStorage.setItem("auth", JSON.stringify(url));
                // this.$emit('getAuth',url)
              }
            })
            .catch(() => { });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// 登录弹窗
::v-deep .el-form-item {

  margin-bottom: 14px;
}

::v-deep .el-form-item__label {
  font-size: 16px;
  color: #606266;
}

.SelectSkin {
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: rgba(60, 60, 60, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 4px 4px 4px 4px;

  .main {

    width: 468px;
    height: 324px;
    background: #FFFFFF;
    margin-top: 7vh;
    // background-color: rgb(229, 229, 190);
    border-radius: 5px;

    .title {
      font-size: 16px;
      border-radius: 4px 4px 0 0;
      height: 50px;
      color: #606266;
      border-bottom: 1px solid #ececec;
      // color: #ff8f19;


      text-align: center;
      line-height: 38px;
      font-weight: normal;
    }

    .form {
      margin-top: 45px;
    }
  }

  .preview {
    color: #606266;
    box-sizing: border-box;
    padding: 0 22px;
    display: flex;
    height: 25px;

    span {
      font-size: 12px;
      line-height: 25px;
    }

    p {
      line-height: 25px;
      border: 1px solid #ccc;
      width: 50px;
      font-size: 11px;
      text-align: center;
      border-radius: 5px;
      margin-left: 18px;
    }
  }

  .paybtn {
    margin-top: 45px;
    position: relative;
  }
}

::v-deep .searchinput {
  width: 290px;
  height: 32px;
  font-size: 13px;
}

::v-deep .searchinput .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
  background: #fcfcfc;
}

.zc {
  &:hover {
    color: blue;
  }

  cursor: pointer;
  position: absolute;
  right: -121px;
}
</style>

