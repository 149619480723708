<template>
  <div class="" style="">
    <!-- <img src="@/assets/head/headAd.png" alt="" style="width: 100%;"> -->

    <div class="myheader flex">
      <div class="headercontent flex">
        <div class="flex acenter topStyle" style="width:827px;">
          <img :src="thelogo.topLogo | montageImgUrl(that)" alt=""
               style="height:80px;margin-right: 30px;object-fit: cover;" @click="toHome">
          <a class="flex allcenter nav" v-for="(item, index) in navbarlist" :key="index"
             :class="{ makeupactive: index == current_makeup }" @click="gotype(item.id, index)"
             style=" margin-right:40px;cursor: pointer;color:white;font-size:15px">
            {{ item.name }}</a>
        </div>
        <div class="flex acenter" style="width: 300px; justify-content: center;">

          <img src="@/assets/head/wechat2.png" alt="" style="width: 30px;height:30px;
    margin-left: 69px;margin-right: 30px;cursor:pointer;position:relative;" @mouseover="showit(index)"
               @mouseleave="hiddenit(index)">

<!--          登陆-->
          <div class="flex acenter" @click.stop="Login">
          <img src="@/assets/head/person.png" alt="" style="width: 38px;height: 38px;">
          <div class="flex allcenter" style="color:#F0E8C4;margin-left: 10px;cursor:pointer">欢迎登陆</div>
          </div>

          <div v-if="yc && index == current" class="qidai flex allcenter">
            <img :src="thelogo.topCode | montageImgUrl(that)" alt=""
                 style="width: 100px;height: 100px;z-index: 99;">
          </div>
        </div>
        <div class="openArea" @click.stop="goOpen">
          <img src="../../../public/img/openArea.png" alt="">
        </div>
        <!-- <div class="mask" :style="{width: browser.width + 'px', height:(browser.height + 20) + 'px' }" @touchmove.prevent.stop @wheel.prevent.stop></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {read, utils} from "xlsx";
import axios from "axios";
import api from "@/api/api";

export default {
  props: ["givelogo"],
  data() {
    return {
      that: this,
      index: '',
      current: 0,
      yc: false,
      list: [],
      current_makeup: 0,
      value: 0,
      timer: "", //计时器
      pwidth: 0, //公告文本的宽度
      current_name: 0,
      navbarlist: [
        {id: 0, name: "首页"},
        {id: 2, name: '开区列表'},
        {id: 1, name: "测评"},
        {id: 4, name: "怀旧专区"},
        {id: 5, name: "GM专区"},
      ],
      username: null,
      showtype: 1,
      auth: null,
      thelogo: this.givelogo,
      outputs: [],
      infoUrl: null,
      body: {
        topCode: '',
        topLogo: ''
      },
      browser: {
        width: '',
        height: ''
      },
    };
  },
  watch: {
    givelogo(val) {

      this.thelogo = val;
    },
  },

  created() {
    // this.getTopNotice();
    // console.log(window.location.hash,'ddd')
    console.log(this.thelogo, "this.thelogo,,,,,,,,,,,,")
    this.browser.width = document.documentElement.clientWidth;
    this.browser.height = document.documentElement.clientHeight;
    console.log(this.browser, 'aoshfahdpfae')
  },
  mounted() {
    this.$bus.$on("showhead", (data) => {
      if (data == 2) {
        // 测评
        this.current_makeup = 2;
      } else if (data == 3) {
        this.current_makeup = 4;
      } else if (data == 4) {
        this.current_makeup = 0;
      } else if (data == 5) {
        this.current_makeup = 3;
      }
    });
    this.$bus.$on("registered", (data) => {
      this.showtype = 2;
      this.username = data.userLoginDTO.username;
      if (data.userLoginDTO.headUrl) {
        this.infoUrl = data.userLoginDTO.headUrl;
      } else {
        this.infoUrl = require("@/assets/images/Bitmap.png");
      }
    });
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo")) || {};
    if (userInfo.userLoginDTO) {
      this.showtype = 2;
      this.username = userInfo.userLoginDTO.username;
      if (!this.infoUrl) {
        if (userInfo.userLoginDTO.headUrl) {
          this.infoUrl = userInfo.userLoginDTO.headUrl;
        } else {
          this.infoUrl = require("@/assets/images/Bitmap.png");
        }
      }
    } else {
      this.showtype = 1;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  filters: {
    montageImgUrl(url, that) {
      if (url?.indexOf('http') == -1) {
        return that.$httpUrl + url;
      } else {
        return url
      }
    }
  },
  methods: {
    toHome() {
      this.$router.push('/')
      this.current_makeup = 0
    },
    showit(index) {
      // 鼠标移入事件mouseover
      this.yc = true;
      this.current = index;
    },
    hiddenit(index) {
      // 鼠标移出事件mouseleave
      this.yc = false;
      this.current = index;
    },
    goOpen() {
      console.log('点击了');
      this.current_makeup = 1
      this.$router.push({
        name: 'moreAd',
      })
    },
    Login(){
      this.$router.push({
        name: 'Login',
      })
    },
    gotype(id, index) {
      console.log('点击', id, index)
      this.current_makeup = index;
      if (id == 1) {
        sessionStorage.setItem("type", 2);
        // const routeOne = this.$router.resolve({
        //   name: "allList",
        // });
        // window.open(routeOne.href, "_blank");
        this.$router.push({
          name: 'allList',
          query: {
            type: 2
          }
        })
      } else if (id == 2) {
        this.$router.push({
          name: 'moreAd',
          // query: {
          //   type: 4
          // }
        })
      } else if (id == 4) {
        sessionStorage.setItem("type", 1);
        // const routeOne = this.$router.resolve({
        //   name: "allList",
        // });
        // window.open(routeOne.href, "_blank");
        this.$router.push({
          name: 'allList',
          query: {
            type: 1
          }
        })
      } else if (id == 5) {
        sessionStorage.setItem("type", 3);
        // const routeOne = this.$router.resolve({
        //   name: "allList",
        // });
        // window.open(routeOne.href, "_blank");
        this.$router.push({
          name: 'allList',
          query: {
            type: 3
          }
        })
      } else {

        const routeOne = this.$router.resolve({
          name: "/",
        });
        this.$router.push('/')
        // window.open(routeOne.href, "_blank");
      }
    },
    register() {
      this.$bus.$emit("openregister", true);
    },
    goout() {
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("auth");
      this.showtype = 1;
      location.reload();
    },
    goSq(v) {
      this.current_name = v;
      var timer;
      let auth = JSON.parse(sessionStorage.getItem("auth")) || "";
      clearTimeout(timer);
      if (v == 0) {
        // 今日推荐
        this.$router.push(
            "/"
        );
      } else if (v == 1) {
        // 开服预告
        this.$router.push(
            "/MyForeshow"
        );
      } else if (v == 2) {
        // 已开推荐
        this.$router.push(
            "/MyRec"
        );
      } else if (v == 3) {
        // 品牌推荐
        this.$router.push(
            "/BrandAd"
        );
      } else if (v == 4) {
        // 免费广告区
        this.$router.push(
            "/MyFree"
        );
      }


    },
    readExcel(e) {
      //表格导入
      var that = this;
      const files = e.target.files;
      if (files.length <= 0) {
        //如果没有文件名
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
        return false;
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0]; //取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容
          that.outputs = []; //清空接收数据
          for (var i = 0; i < ws.length; i++) {
            var sheetData = {
              name: ws[i].name,
              sex: ws[i].sex,
              name: ws[i].age,
            };
            that.outputs.push(sheetData);
          }
          this.$refs.upload.value = "";
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    }
  }
};
</script>

<style lang="scss" scoped>
.qidai {
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 500;
  height: 28px;
  color: #404040;
  width: 70px;
  top: 112px;
  right: 31%;
  position: absolute;
  background: white;
  border: 1px solid #ececec;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: .5;
  // cursor:no-drop;
  // pointer-events: none;
}

.openArea {
  cursor: pointer;
  position: relative;
  z-index: 99999;
  width: 133px;
  height: 97px;

  img {
    width: 100%;
    height: 100%;
  }
}

.adcss {
  width: 386px;
  height: 45px;
  border: 1px solid #FF7226;
  background: #FBEFEA;
}

.makeupactive {
  font-weight: bold;
  // border-bottom: 4px solid #ff8f19 !important;
  color: #FF7226 !important;
  // background: #FBEFEA !important;
}

.nav:hover {
  font-weight: bold;
  // border-bottom: 4px solid #ff8f19 !important;
  color: #FF7226 !important;
  // background: #FBEFEA !important;
}

.contact {
  cursor: pointer;
  margin-right: 30px;
  color: white;
}


.register {
  width: 40px;
  color: #fff;
  cursor: pointer;

  font-size: 14px;
}

.register:hover {
  color: #ff8f19
}

::v-deep .el-carousel__item.is-animating {
  transition: transform 2s ease-in-out
}

.navbar {

  // width:90px;
  // background: pink;
  margin-right: 30px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

::v-deep .searchinput .el-input__inner {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  width: 315px;
  height: 32px !important;
  line-height: 32px !important;
  background: #fcfcfc;
}

::v-deep .searchinput {
  width: 315px;
  font-size: 13px;
}

.myheader {
  margin-top: -4px;
  position: relative;
  // opacity: 0.9;
  font-family: light;
  align-items: center;
  height: 80px;
  background: #323232;
  color: 'rgb(255, 255, 255)';
  width: 100%;
  /*吸顶效果*/
  // position: fixed;
  // top: 0;
  z-index: 999;

  .headercontent {
    width: 1260px;
    height: 100%;
    margin: 0 auto;

    h1 {
      width: 160px;
      display: flex;
      align-items: center;
      font: normal 28px Cookie, Arial, Helvetica, sans-serif;
    }

    nav {
      font-weight: 400;
      margin-left: 65px;
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;

      div {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        color: #fff;
        font-size: 20px;
        text-align: center;
        position: relative;
        font-weight: 500;
        letter-spacing: 4px;

        &:before {
          content: "";
          background: #ff8f19;
          height: 3px;
          top: 0px;
          // bottom: 0px;
          width: 0;
          position: absolute;
          left: 28%;
          width: 0px;
          transition: all 0.4s;
        }

        &:after {
          content: "";
          background: #ff8f19;
          height: 3px;
          top: 0px;
          width: 0;
          position: absolute;
          right: 28%;
          width: 0px;
          transition: all 0.4s;
        }

        .navbar:hover {
          font-weight: bold;
          // text-shadow: 0 0 10px #FF7226,0 0 20px #FF7226,0 0 30px #FF7226,0 0 40px #FF7226;
          color: #ff8f19;
          // color: white;
          opacity: 1;

          &:after {
            width: 60%;
          }

          &:before {
            width: 60%;
          }
        }
      }

      .active {
        font-weight: bold;
        // text-shadow: 0 0 20px #FF7226,0 0 20px #FF7226,0 0 30px #FF7226,0 0 60px #FF7226;
        color: #ff8f19;
        // color: white;
        pointer-events: all;
        opacity: 1;

        &:after {
          width: 60%;
        }

        &:before {
          width: 60%;
        }
      }
    }

    .topStyle {
      display: flex;
      align-content: center;
    }
  }
}

#box {
  // background: #fffaee;
  margin-left: 30px;
  width: 1030px;
  height: 46px;
  margin-top: 13px;
  // font-size:20px;
  position: relative;
  // margin-bottom: 20px;
  // background: #fffaee;
  // background: white;

  .paimgbox {
    margin-left: 25px;
    width: 21px;
    height: 20px;
    margin-top: 16px;
    /* background: #FFFAEE; */
    font-size: 14px;
    margin-right: 5px;
  }

  .marquee-box {

    scrollbar-width: none;
    /* 取消火狐自带滚动条 */
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    // background: white;

    // background: #fffaee;
    .pWidth {
      height: 46px;

      padding: 0;
      margin: 0;
      line-height: 51px;
      padding-left: 13px;
      display: block;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 600;
      color: #fff;
      // color: #606266;
      font-size: 20px;
    }
  }
}


@keyframes myfirst {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes myfirst

  /* Safari 与 Chrome */
{
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}
</style>
