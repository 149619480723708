import "@babel/polyfill";
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// 按需引入
import {
    RadioGroup,
    Button,
    Tooltip,
    Input,
    Dialog,
    Pagination,
    Message,
    Carousel,
    CarouselItem,
    Radio,
    Form,
    FormItem,
    Upload,
    Image,
    Menu,
    MenuItem,
    Submenu,
    Table,
    TableColumn,


} from 'element-ui';

import jquery from "jquery";
 	Vue.prototype.$ = jquery;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router';
import '../src/common/common.scss';


Vue.prototype.$message = Message
Vue.prototype.$bus = new Vue() // 全局总线
Vue.prototype.$EventBus = new Vue();
Vue.use(VueAxios, axios)

Vue.use(Button).use(Tooltip).use(Input).use(Dialog).use(Pagination).use(Carousel).use(CarouselItem).use(Radio).use(Form)
.use(FormItem).use(RadioGroup).use(Upload).use(Image).use(Carousel).use(CarouselItem).use(Table).use(TableColumn).use(Menu)
    .use(Menu).use(MenuItem).use(Submenu);
Vue.config.productionTip = false

// 图片前缀 https://www.7tx.com
//  const httpUrl = 'https://site.tgs.plus:5565'
 const httpUrl = 'https://api.7tx.com:5565'
//  const httpUrl = 'http://192.168.1.166:5565'
//  const httpUrl = 'http://161.213.40.118'
//  const httpUrl = 'https://api.3000y.cc:2096'
//  const httpUrl = 'http://192.168.1.106:6800'
// const httpUrl = 'https://www.3000y.net:8081'
// const httpUrl = 'https://api.3000.run:2096'
// const httpUrl = 'https://api.3000hy.com:2096'
// const httpUrl = ' HTTP: 'https://cn-hk-bgp-4.openfrp.top:51970' '
// 引入路由

Vue.prototype.$httpUrl = httpUrl
// Vue.use(ElementUI);

new Vue({

    render: h => h(App),
    // 注册路由 ：底下的写法是 path value 一致省略v
    // 注册路由信息：当这里书写router的时候，组件身上都拥有$route,$router属性
    router,
    httpUrl,
    // 在main.js中完成全局事件总线的安装配置
    beforeCreate() {
        Vue.prototype.$bus = this //安装全局事件总线
    }
}).$mount('#app')
