import http from '../axios/request.js';

// 请求
export default {
    //--------------post请求---------------
    //今日开服
    getToday(data) {
        return http.post("/show-ad/home/today", data)
    },
    // // 开服预告
    getFutureOpen(data) {
        return http.post("/show-ad/future/open", data)
    },
    // 已开推荐
    getAlreadyOpen(data) {
        return http.post("/show-ad/already/open", data)
    },
    // // 精品推荐
    getHomeOpen(data) {
        return http.post("/show-ad/home/top", data)
    },
    // // 热词
    getHotad(data) {
        return http.post("/show-ad/hot-ad", data)
    },
    // 图片广告
    getImagesad(data) {
        return http.post("/show-ad/images-ad", data)
    },
    // 搜索
    getSearchad(data) {
        return http.post("/show-ad/search-ad", data)
    },
    // // 联系渠道
    getContact(data) {
        return http.post("/foregroundInfo/getContact", data)
    },
    // // 公告列表分页？
    getNoticeByPage(data) {
        return http.post("/foregroundInfo/getNoticeByPage", data)
    },
    //查看指定公告
    getOneNotice(data) {
        return http.post("/foregroundInfo/getOneNotice", data)
    },
    // 置顶的公告
    getTopNotice(data) {
        return http.post("/foregroundInfo/getTopNotice", data)
    },
    //查看网站页尾信息
    getTextInfoFooter(data) {
        return http.post("/foregroundInfo/getTextInfoFooter", data)
    },
    //查看服务中心信息 是条款吗?
    getTextInfoService(data) {
        return http.post("/foregroundInfo/getTextInfoService", data)
    },
    // 意见反馈提示
    getTextInfoStatement(data) {
        return http.post("/foregroundInfo/getTextInfoStatement", data)
    },
    //查看助力/侧边悬浮/意见反馈是否开启
    getBasicStatus(data) {
        return http.post("/show-ad/basic/status", data)
    },
    //增加热力值
    getAddHotSport(data) {
        return http.post("/show-ad/add/hotSport", data)
    },
    //查看热力值差异 传参id
    getCheckHotSport(data) {
        return http.post("/show-ad/difference/hotSport", data)
    },
    // 传意见反馈
    getuserfeedback(data) {
        return http.post("/user/feedback", data)
    },
    // 上火爆区
    getGoHotArea(data) {
        return http.post("/show-ad/go/hotArea", data)
    },
    // 登录
    login(data) {
        return http.post("/user/login", data)
    },
    // 密码校验
    checkPassword(data) {
        return http.post("/user/checkPassword", data)
    },
    // 查询三千币
    getMoney() {
        return http.post("/user/getMoney")
    },
    // 微信native支付
    getWxNatice(data) {
        return http.post("/pay/wx/native", data)
    },
    // 支付宝native支付
    getPagePay(data) {
        return http.post("/pay/ali/pagePay", data)
    },
    // 查询支付状态
    getOrderStatus(data) {
        return http.post("/pay/getOrderStatus", data)
    },
    // 广告点击量统计
    adHitCount(data) {
        return http.post("/show-ad/adHitCount", data)
    },
    // 免费广告
    getFreeAd(data) {
        return http.post("/show-ad/getFreeAd", data)
    },
    // 品牌广告查询
    getBrandAd(data) {
        return http.post("/show-ad/getBrandAd", data)
    },
    // 其他游戏接口
    getEmpowerAd(data) {
        return http.post("/show-ad/getEmpowerAd", data)
    },
    // 子页面信息接口
    getSubPage(data) {
        return http.post("/show-ad/getSubPage", data)
    },
}
