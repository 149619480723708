import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
// import router from '../router'
const DataURL = {
//  HTTP:'http://192.168.1.166:5565'
//  HTTP:'https://site.tgs.plus:5565'
 HTTP:'https://api.7tx.com:5565'
//    HTTP: '/api/'
// HTTP: 'http://localhost:5565'
}
// new 对象实例
// class jsonData {
//     constructor(data) {
//         this.vision = '1'
//         this.code = '0'
//         this.message = 'ok'
//         this.data = data
//     }
// }

// 重新请求次数
axios.defaults.retry = 2;
// 重新请求时间
axios.defaults.retryDelay = 10000;

const ax = axios.create({
    // timeout: 7000,
})
ax.interceptors.request.use(
    config => {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {}
        const Token = userInfo.utoken
        if (Token) config.headers['u-token'] = Token
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

ax.interceptors.response.use(
    (res) => {

        const data = res.data
        return data;
        // console.log(data,'打印请求结果')
        if (data.code == '101') {
            // return Promise.reject(new Error(data.message || 'Error'))
            return '正在支付中'
        }
        if (data.message == "侧边悬浮功窗能已关闭，请联系系统管理人员"
        ) {
            // return Promise.reject(new Error(data.message || 'Error'))
            return '侧边栏已关闭'
        }
        if(data.code == '200'){
            return data.data
        }

        if(data.code == '10000'){
            return data.data
        }
        // if (data.code !== '0' && data.code != '101') {
        //     // if(data.code == 'A0014'){
        //     //     Message({
        //     //         message: '您已注销，您可以取消以停留在此页面上，或重新登录，确认注销',
        //     //         type: 'error',
        //     //         duration: 2 * 1000
        //     //     })
        //     // }else{
        //     Message({
        //         message: data.message || 'Error',
        //         type: 'error',
        //         duration: 2000
        //     })
        //     // }

        //     // A0001: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        //     if (data.code === 'A0001' || data.code === 'A0004' || data.code === '50014' || data.code === 'A0014') {
        //         // to re-login
        //         MessageBox.confirm('您已注销，您可以取消以停留在此页面上，或重新登录，确认注销', {
        //             confirmButtonText: '确定',
        //             cancelButtonText: '取消',
        //             // 隐藏x号
        //             showClose: false,
        //             center: true,
        //         }).then(() => {
        //             sessionStorage.clear()
        //             location.reload()
        //         })
        //     }
        //     return Promise.reject(new Error(data.message || 'Error'))
        // }
        // else {
        //     return data.data
        //     console.log(data,'data的data',data.data)
        // }
    },

    async (err) => {
        // console.log('err' + error) // for debug
        // Message({
        //     message: error.message || error,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        const config = err.config;
        if (!config || !config.retry) return Promise.reject(err);
        // 手动给config添加当前重试次数的属性
        config.__retryCount = config.__retryCount || 0;

        // 达到上限时reject
        if (config.__retryCount >= config.retry) {
            return Promise.reject(err);
        }

        // 重试次数+1
        config.__retryCount += 1;

        // 定时器在达到重试间隔时resolve往下执行
        const backoff = new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.retryDelay || 1);
        });

        // 返回一个promise，在定时器结束后重新调用timeOutRequest(config)发起请求，timeOutRequest之前创建的实例
        await backoff
        return await ax(config)
    }
)

const http = {
    post(url, data) {
        return ax.post(DataURL.HTTP + url, data)
    },
    postFrom(url, data) {
        return ax.post(DataURL.HTTP + url, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    },
    get(url, data) {
        return ax.get(DataURL.HTTP + url,  data)
    },
    post2(url, data) {

        // `${url}/${data}`
        return ax.post(`${DataURL}${url}?${data}`);
        // return ax.post(DataURL + url+'/'+data,);
    },
}

//导出
export default http
